import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavMenu } from '../nav-link/nav-link.component';

@Component({
  selector: 'app-barnavlatiz',
  templateUrl: './barnavlatiz.component.html',
  styleUrls: ['./barnavlatiz.component.scss']
})
export class BarnavlatizComponent implements OnInit {
  navMenu: NavMenu[];
  constructor() {
    this.navMenu = [
      { link: '/home', name: 'INICIO' },
      { link: '/about', name: 'QUIENES SOMOS' },
      { link: '/services', name: 'SERVICIOS' },
      { link: '/contact', name: 'CONTACTENOS' }
    ];
  }

  ngOnInit(): void {
  }

}
