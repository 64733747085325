<div class="footer-call-to-action">
    <div class="container">
        <div class="row">

            <div class="col-md-4 sm-text-center">
                <h3>Regístrese para recibir actualizaciones </h3>
                <p>Al suscribirse a nuestra lista de correo siempre estará actualizado. </p>
            </div>
            <div class="col-md-8 text-right sm-text-center">
                <input type="text" name="name" placeholder="Nombre completo">
                <input type="text" name="email" placeholder="Correo electronico">
                <a class="thm-btn inverse mt-sm-15">Subscribase ahora</a>
            </div>

        </div>
    </div>
</div>
<footer class="footer sec-padding" style=" padding:55px 0;">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-md-3">
                <div class="footer-widget about-widget">
                    <a>
                        <img src="assets/img/intic_logo2.png" alt="Awesome Image" />
                    </a>
                    <ul class="contact">
                        <!--<li><i class="fa fa-map-marker"></i> <span>00 Monroe Ave, Roseland, NJ, 07068 </span></li>-->
                        <li><i class="fa fa-phone"></i> <span>+51 955-111-265</span></li>
                        <!--<li><i class="fa fa-phone"></i> <span>(973) 226-6181</span></li>-->
                        <li><i class="fa fa-envelope-o"></i> <span>info@intic.pe</span></li>
                    </ul>
                    <ul class="social">
                        <!--<li><a href="https://www.facebook.com/inticperu"><i class="fa fa-facebook"></i></a></li>-->
                        <li><a href="https://twitter.com/inticPeru"><i class="fa fa-twitter"></i></a></li>
                        <li>
                            <a href="https://www.youtube.com/channel/UCNmw96N8aAiZm0fbCuDmVpw/featured?reload=9&disable_polymer=1">
                                <i class="fa fa-youtube"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6  col-md-2">
                <div class="footer-widget quick-links">
                    <h3 class="title">Navegación</h3>
                    <ul>
                        <li *ngFor="let item of navMenu"><a [routerLink]="[item.link]">{{item.name}}</a></li>

                    </ul>
                </div>
            </div>
            <!--
          <div class="col-sm-6 latest-post col-md-3">
              <div class="footer-widget latest-post">
                  <h3 class="title">Ultimas noticias</h3>
                  <ul>
                      <li>
                          <span class="border"></span>
                          <div class="content">
                              <a href="classes-list.html">Sed ut perspiciatis unde omnis iste natus error sit voluptatem...</a>
                              <span>jan 1, 2017</span>
                          </div>
                      </li>
                      <li>
                          <span class="border"></span>
                          <div class="content">
                              <a href="classes-list.html">Sed ut perspiciatis unde omnis iste natus error sit voluptatem...</a>
                              <span>jan 1, 2017</span>
                          </div>
                      </li>
                      <li>
                          <span class="border"></span>
                          <div class="content">
                              <a href="classes-list.html">Sed ut perspiciatis unde omnis iste natus error sit voluptatem ... </a>
                              <span>jan 1, 2017</span>
                          </div>
                      </li>
                  </ul>
              </div>
          </div>-->
            <div class="col-sm-6 col-md-7">
                <div class="footer-widget contact-widget">
                    <h3 class="title">Formulario de contacto</h3>
                    <form action="submit.php" class="contact-form" id="footer-cf" novalidate="novalidate">
                        <input type="text" name="name" placeholder="Nombre completo">
                        <input type="text" name="email" placeholder="Dirección de E-mail">
                        <textarea name="message" placeholder="Su mensaje"></textarea>
                        <button type="reset">Enviar</button>
                    </form>
                    <div id="result"></div>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="footer-bottom">
    <div class="container text-center">
        <p>© 2018 - {{year | date:'yyyy'}} INTIC SOLUTIONS S.A.C - TODOS LOS DERECHOS RESERVADOS</p>
    </div>
</div>
