<div class="top-bar hidden-xs ">
  <div class="container">
    <div class="social-icons pull-left">
      <ul>
        <!--<li><a href="https://www.facebook.com/inticperu"><i class="fa fa-facebook"></i></a></li>-->
        <li><a href="https://twitter.com/inticPeru"><i class="fa fa-twitter"></i></a></li>
        <li><a href="https://www.youtube.com/channel/UCNmw96N8aAiZm0fbCuDmVpw/featured?reload=9&disable_polymer=1"><i
              class="fa fa-youtube"></i></a></li>
      </ul>
    </div>
    <!-- /.social-icons -->
    <div class="social-icons pull-right">
      <!--<ul>
                <li><a href="#"><i class="fa fa-commenting"></i> Chat en vivo</a></li>
                <li><a ><i class="fa fa-headphones"></i> Soporte</a></li>
            </ul>-->
    </div>
    <!-- /.left-text -->
  </div>
</div>
<header class="header">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-4 col-md-4">
        <a [routerLink]="['/home']">
          <div class="logo">
            <img src="assets/img/intic_logo.svg" alt="Awesome Image" />
            <p style="font-size: small; color: #fbb13b;">Inovación tecnologica de la información y conectividad</p>
          </div>
        </a>
      </div>
      <div class="col-xs-12 col-sm-8 col-md-8 hidden-xs ">
        <div class="header-right-info pull-right sm-pull-none clearfix">
          <div class="single-header-info pb-sm-20">
            <div class="icon-box">
              <div class="inner-box">
                <i class="flaticon-interface-2"></i>
              </div>
            </div>
            <div class="content">
              <h3>EMAIL</h3>
              <p>info@intic.pe</p>
            </div>
          </div>
          <div class="single-header-info">
            <div class="icon-box">
              <div class="inner-box">
                <i class="flaticon-telephone"></i>
              </div>
            </div>
            <div class="content">
              <h3>LLamenos</h3>
              <p><b> +51 955 111 265</b></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<nav class="mainmenu-area stricky slideIn animated">
  <div class="container">
    <div class="row">
      <div class="col-lg-9 col-sm-12 col-xs-12">
        <div class="navigation">
          <div class="nav-header pull-left">
            <ul>
              <li><a [routerLink]="['/home']" routerLinkActive="active">Inicio</a></li>
              <!--<li class="dropdown">
                                <a>Cursos</a>
                                <ul class="submenu">
                                    <li><a>Redes</a></li>
                                </ul>
                            </li>-->
              <li><a [routerLink]="['/about']" routerLinkActive="active">Quienes somos</a></li>
              <!--<li><a >Productos</a></li>-->
              <li><a [routerLink]="['/services']" routerLinkActive="active">Servicios</a></li>
              <!--<li>
                                <a >Blog</a>
                            </li>-->
              <li>
                <a [routerLink]="['/contact']" routerLinkActive="active">Contactenos</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="donate-col col-xs-12 col-sm-12 col-lg-3 col-md-3">
        <div class="donate-btn clearfix">
          <div class="nav-footer pull-left">
            <button (click)="showBarLeft()"><i class="fa fa-bars"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
