<nav @menuExpand class="mainmenu-area stricky slideIn stricky-fixed fadeInDown animated">
  <div class="container">
    <div class="row">
      <div class="col-lg-9 col-sm-12 col-xs-12">
        <div class="navigation">
          <div class="nav-header pull-left">
            <ul>
              <li><a [routerLink]="['/home']" routerLinkActive="active">Inicio</a></li>
              <!--<li class="dropdown">
                            <a>Cursos</a>
                            <ul class="submenu">
                                <li><a>Redes</a></li>
                            </ul>
                        </li>-->
              <li><a [routerLink]="['/about']" routerLinkActive="active">Quienes somos</a></li>
              <!--<li><a >Productos</a></li>-->
              <li><a [routerLink]="['/services']" routerLinkActive="active">Servicios</a></li>
              <!--<li>
                            <a >Blog</a>
                        </li>-->
              <li>
                <a [routerLink]="['/contact']" routerLinkActive="active">Contactenos</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="donate-col col-xs-12 col-sm-12 col-lg-3 col-md-3">
        <div class="donate-btn clearfix">
          <div class="nav-footer pull-left">
            <button (click)="showBarLeft()"><i class="fa fa-bars"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
