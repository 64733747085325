import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  modeMobil: string;
  opened = false;
  isMinimal: boolean;
  screenWidth: number;
  @HostListener('window:resize', ['$event']) resizeWindow($event): void {
    this.screenWidth = $event.target.innerWidth;
    this.openModeMovil(this.screenWidth);
  }
  @HostListener('window:scroll', ['$event']) changeHeader($event): void {
    const witghScroll = $event.target.children[0].scrollTop;
    // console.log(witghScroll);
    if (witghScroll > 242) {
      this.isMinimal = true;
    } else {
      this.isMinimal = false;
    }
  }
  constructor() {
    this.screenWidth = (window as Window).innerWidth;
    this.modeMobil = 'over'; // 'side';
    this.isMinimal = false;
  }

  ngOnInit(): void {
    this.openModeMovil(this.screenWidth);
  }

  private openModeMovil(screen: number): void {
    /*if (screen <= 450) {
      this.isMobil = true;
      this.opened = false;
    } else if (screen < 766) {
      this.isMobil = true;
      this.opened = false;
    } else {
      this.isMobil = false;
      this.opened = false;
    }*/
    this.opened = false;
  }

  showBarLeft(snav): void {
    this.opened = snav.toggle();
  }

}
