import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MaterialModule } from '../material/material.module';
import { HeaderMobilComponent } from './header-mobil/header-mobil.component';
import { BarnavlatizComponent } from './barnavlatiz/barnavlatiz.component';
import { NavLinkComponent } from './nav-link/nav-link.component';
import { HeaderMinimalComponent } from './header-minimal/header-minimal.component';
export const shared = [
  HeaderComponent,
  FooterComponent,
  HeaderMobilComponent,
  HeaderMinimalComponent,
  BarnavlatizComponent,
  NavLinkComponent
];

@NgModule({
  declarations: [
    ...shared,
  ],
  exports: [
    ...shared
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule
  ]
})
export class SharedModule { }
