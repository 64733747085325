<mat-sidenav-container hasBackdrop="true">
    <mat-sidenav #snav [mode]="modeMobil" [opened]="opened" [fixedInViewport]="true">
        <app-barnavlatiz></app-barnavlatiz>
    </mat-sidenav>
    <mat-sidenav-content>
        <!--<app-header-mobil *ngIf="isMobil else noMobil"></app-header-mobil>
        <ng-template #noMobil>
            <app-header></app-header>
            <app-header-minimal *ngIf="isMinimal"></app-header-minimal>
        </ng-template>-->
        <app-header (itemEvent)="showBarLeft(snav)"></app-header>
        <app-header-minimal (itemEvent)="showBarLeft(snav)" *ngIf="isMinimal"></app-header-minimal>
        <div class="main">
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>
