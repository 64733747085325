import { Component, OnInit } from '@angular/core';
export interface NavMenu {
  link: string;
  name: string;
}
@Component({
  selector: 'app-nav-link',
  templateUrl: './nav-link.component.html',
  styleUrls: ['./nav-link.component.scss']
})
export class NavLinkComponent implements OnInit {
  navMenu: NavMenu[];
  constructor() {
    this.navMenu = [
      { link: '/home', name: 'INICIO' },
      { link: '/about', name: 'QUIENES SOMOS' },
      { link: '/services', name: 'SERVICIOS' },
      { link: '/contact', name: 'CONTACTENOS' }
    ];
  }

  ngOnInit(): void {
  }

}
