import { Component, OnInit } from '@angular/core';
import { NavMenu } from '../nav-link/nav-link.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  year: Date;
  navMenu: NavMenu[];
  constructor() { }

  ngOnInit(): void {
    this.year = new Date();
    this.navMenu = [
      { link: '/home', name: 'INICIO' },
      { link: '/about', name: 'QUIENES SOMOS' },
      { link: '/services', name: 'SERVICIOS' },
      { link: '/contact', name: 'CONTACTENOS' }
    ];
  }

}
