import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';
@Component({
  selector: 'app-header-minimal',
  templateUrl: './header-minimal.component.html',
  styleUrls: ['./header-minimal.component.scss'],
  animations: [
    trigger('menuExpand', [
      transition(':enter', [
        style({
          opacity: 0
        }), animate('500ms',
          style({
            opacity: 1
          }))
      ])
    ])
  ]
})
export class HeaderMinimalComponent implements OnInit {
  // htmlPageHeader: HTMLElement;
  @Output() itemEvent = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }

  showBarLeft(): void{
    this.itemEvent.emit(true);
    console.log("hola minimal");
  }

}
