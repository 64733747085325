import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // htmlPageHeader: HTMLElement;
  @Output() itemEvent = new EventEmitter<boolean>();
  constructor() {
  }
  ngOnInit(): void {
    // this.htmlPageHeader = (document.querySelector('.header-main') as HTMLElement);
  }
  showBarLeft(): void{
    this.itemEvent.emit(true);
  }
}
